// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SlackTab-module__LyvDH3_885BJi9rX7BTC {
  display: flex;
  justify-content: flex-end;
}

.SlackTab-module__A2dzf1zmykr15_42BKBr {
  text-align: center;
  width: 725px;
}

.SlackTab-module__dHZK4AOHq71gcisynRsb {
  margin-right: 4px;
  align-self: baseline;
}
`, "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/tabs/SlackTab/SlackTab.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.slack-infoblock {\n  text-align: center;\n  width: 725px;\n}\n\n.external-link-style {\n  margin-right: 4px;\n  align-self: baseline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `SlackTab-module__LyvDH3_885BJi9rX7BTC`,
	"slack-infoblock": `SlackTab-module__A2dzf1zmykr15_42BKBr`,
	"external-link-style": `SlackTab-module__dHZK4AOHq71gcisynRsb`
};
export default ___CSS_LOADER_EXPORT___;
