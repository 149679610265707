// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LegacyNavTabsBar-module__eBU1UFlh3lJJhRZ3So_g{overflow-x:auto;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./navbar/LegacyNavTabsBar.module.scss"],"names":[],"mappings":"AAAA,+CACE,eAAA,CACA,kBAAA","sourcesContent":[".root {\n  overflow-x: auto;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `LegacyNavTabsBar-module__eBU1UFlh3lJJhRZ3So_g`
};
export default ___CSS_LOADER_EXPORT___;
