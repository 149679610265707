// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rotation-module__hG0YE0M0Th5M2YmF9cXY {
  transition: background-color 300ms;
  min-height: 28px;
  overflow-x: hidden;
}

.Rotation-module__QQs4J5DsD8asuQuOQ2_6 {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Rotation-module__hG0YE0M0Th5M2YmF9cXY:hover {
  background: var(--secondary-background);
}

.Rotation-module__XMWIS7_d93GTOtk1Tr_O {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 4px;
  position: relative;
}

.Rotation-module__sFFKKx2j_kRsysoy7ay0 {
  width: 100%;
  display: flex;
  transition: opacity 500ms ease;
  opacity: 1;
}

.Rotation-module__lB5aSof4cprBqItIiY_p {
  opacity: 0.5;
}

.Rotation-module__fgroqlJxq3ugxlzKaFod {
  position: absolute;
  left: 450px;
  width: 1px;
  background: var(--gradient-brandVertical);
  top: -10px;
  bottom: -10px;
  z-index: 1;
}

.Rotation-module__CeVPxWDP8L0GN0cmG5Nl {
  height: 28px;
  cursor: pointer;
  text-align: center;

  /*  background: #5f505633;
    border: 1px dashed #5c474d;
    color: rgba(209, 14, 92, 0.5); */
  margin: 0 2px;
}

.Rotation-module__HO8dSm9E0aP9RG85YEQZ {
  position: absolute;
  top: -9px;
  transition: left 500ms ease, opacity 500ms ease, transform 500ms ease;
  transform-origin: bottom center;
  opacity: 0;
  transform: scale(0);
}

.Rotation-module__LwH5yr09Q71GF8qg3sM_ {
  transform: scale(1);
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./containers/Rotation/Rotation.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,yCAAyC;EACzC,UAAU;EACV,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;;EAElB;;oCAEkC;EAClC,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,qEAAqE;EACrE,+BAA+B;EAC/B,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".root {\n  transition: background-color 300ms;\n  min-height: 28px;\n  overflow-x: hidden;\n}\n\n.loader {\n  height: 28px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.root:hover {\n  background: var(--secondary-background);\n}\n\n.timeline {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  padding-bottom: 4px;\n  position: relative;\n}\n\n.slots {\n  width: 100%;\n  display: flex;\n  transition: opacity 500ms ease;\n  opacity: 1;\n}\n\n.slots__transparent {\n  opacity: 0.5;\n}\n\n.current-time {\n  position: absolute;\n  left: 450px;\n  width: 1px;\n  background: var(--gradient-brandVertical);\n  top: -10px;\n  bottom: -10px;\n  z-index: 1;\n}\n\n.empty {\n  height: 28px;\n  cursor: pointer;\n  text-align: center;\n\n  /*  background: #5f505633;\n    border: 1px dashed #5c474d;\n    color: rgba(209, 14, 92, 0.5); */\n  margin: 0 2px;\n}\n\n.pointer {\n  position: absolute;\n  top: -9px;\n  transition: left 500ms ease, opacity 500ms ease, transform 500ms ease;\n  transform-origin: bottom center;\n  opacity: 0;\n  transform: scale(0);\n}\n\n.pointer--active {\n  transform: scale(1);\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Rotation-module__hG0YE0M0Th5M2YmF9cXY`,
	"loader": `Rotation-module__QQs4J5DsD8asuQuOQ2_6`,
	"timeline": `Rotation-module__XMWIS7_d93GTOtk1Tr_O`,
	"slots": `Rotation-module__sFFKKx2j_kRsysoy7ay0`,
	"slots__transparent": `Rotation-module__lB5aSof4cprBqItIiY_p`,
	"current-time": `Rotation-module__fgroqlJxq3ugxlzKaFod`,
	"empty": `Rotation-module__CeVPxWDP8L0GN0cmG5Nl`,
	"pointer": `Rotation-module__HO8dSm9E0aP9RG85YEQZ`,
	"pointer--active": `Rotation-module__LwH5yr09Q71GF8qg3sM_`
};
export default ___CSS_LOADER_EXPORT___;
