// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LiveSettings-module__mh3IkaaRBIBufTE41hkG {
  vertical-align: top;
}

.LiveSettings-module__e4dyWNCY_WdkTf803uf1 {
  display: flex;
  justify-content: space-between;
}

.LiveSettings-module__PugshBUKUk0V4BJ3UVho {
  /* vertical-align: top; */
}

.LiveSettings-module__iSCVaaLhJkYlkSxJ1vUS {
  color: green;
}

.LiveSettings-module__dnYMxuErlSi0R4UjoZ5d > a {
  color: var(--primary-text-link);
}

.LiveSettings-module__dnYMxuErlSi0R4UjoZ5d {
  word-wrap: break-word;
  word-break: break-word;
}
`, "",{"version":3,"sources":["webpack://./pages/settings/tabs/LiveSettings/LiveSettings.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;AACxB","sourcesContent":[".align-top {\n  vertical-align: top;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.row {\n  /* vertical-align: top; */\n}\n\n.check-icon {\n  color: green;\n}\n\n.description-style > a {\n  color: var(--primary-text-link);\n}\n\n.description-style {\n  word-wrap: break-word;\n  word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"align-top": `LiveSettings-module__mh3IkaaRBIBufTE41hkG`,
	"header": `LiveSettings-module__e4dyWNCY_WdkTf803uf1`,
	"row": `LiveSettings-module__PugshBUKUk0V4BJ3UVho`,
	"check-icon": `LiveSettings-module__iSCVaaLhJkYlkSxJ1vUS`,
	"description-style": `LiveSettings-module__dnYMxuErlSi0R4UjoZ5d`
};
export default ___CSS_LOADER_EXPORT___;
