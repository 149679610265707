// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduleIcalLink-module__KpqkHBToHnP5ZLKQ6F15 {
  background-color: var(--secondary-background);
}
`, "",{"version":3,"sources":["webpack://./containers/ScheduleIcalLink/ScheduleIcalLink.module.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;AAC/C","sourcesContent":[".link-container {\n  background-color: var(--secondary-background);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link-container": `ScheduleIcalLink-module__KpqkHBToHnP5ZLKQ6F15`
};
export default ___CSS_LOADER_EXPORT___;
