// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserSettings-module__UjgHox0MIEke4UpB2nA9 {
  width: 860px; /* wide enough so that all tabs fit in */
}

.UserSettings-module__UjgHox0MIEke4UpB2nA9.UserSettings-module__S8l7YXjxla_NolPqFqer {
  --spacing: 10px;

  width: calc(100% - var(--spacing) * 2); /* allow lateral spacing */
  max-width: 1100px;
}

.UserSettings-module__eyzQJ6z4AWUTbR6eoCdb {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 10px;

  &:empty {
    display: none;
  }
}

.UserSettings-module__vWBP8M79HJkVMvBAD5LG {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./containers/UserSettings/UserSettings.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE,wCAAwC;AACxD;;AAEA;EACE,eAAe;;EAEf,sCAAsC,EAAE,0BAA0B;EAClE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;;EAET;IACE,aAAa;EACf;AACF;;AAEA;EACE,SAAS;AACX","sourcesContent":[".modal {\n  width: 860px; /* wide enough so that all tabs fit in */\n}\n\n.modal.modal-wide {\n  --spacing: 10px;\n\n  width: calc(100% - var(--spacing) * 2); /* allow lateral spacing */\n  max-width: 1100px;\n}\n\n.alerts-container {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n  gap: 10px;\n\n  &:empty {\n    display: none;\n  }\n}\n\n.alert {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `UserSettings-module__UjgHox0MIEke4UpB2nA9`,
	"modal-wide": `UserSettings-module__S8l7YXjxla_NolPqFqer`,
	"alerts-container": `UserSettings-module__eyzQJ6z4AWUTbR6eoCdb`,
	"alert": `UserSettings-module__vWBP8M79HJkVMvBAD5LG`
};
export default ___CSS_LOADER_EXPORT___;
