// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GrafanaTeamSelect-module__oYArYu0JlM_OSXQ7dZI_{width:400px}.GrafanaTeamSelect-module__JVj4tuphCIWyMbXVcwaQ{display:flex}.GrafanaTeamSelect-module__yDq7jAfMfiTqFjY8cdzl{color:var(--primary-text-link)}.GrafanaTeamSelect-module__TRUeiqMdxOzCuRLnQict{margin-left:4px}`, "",{"version":3,"sources":["webpack://./containers/GrafanaTeamSelect/GrafanaTeamSelect.module.scss"],"names":[],"mappings":"AAAA,gDACE,WAAA,CAGF,gDACE,YAAA,CAGF,gDACE,8BAAA,CAGF,gDACE,eAAA","sourcesContent":[".root {\n  width: 400px;\n}\n\n.teamSelectLabel {\n  display: flex;\n}\n\n.teamSelectLink {\n  color: var(--primary-text-link);\n}\n\n.teamSelectInfo {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GrafanaTeamSelect-module__oYArYu0JlM_OSXQ7dZI_`,
	"teamSelectLabel": `GrafanaTeamSelect-module__JVj4tuphCIWyMbXVcwaQ`,
	"teamSelectLink": `GrafanaTeamSelect-module__yDq7jAfMfiTqFjY8cdzl`,
	"teamSelectInfo": `GrafanaTeamSelect-module__TRUeiqMdxOzCuRLnQict`
};
export default ___CSS_LOADER_EXPORT___;
