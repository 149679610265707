// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TelegramInfo-module__xFKifzdlLVKOrnwF8YMF {
  color: var(--primary-text-link);
}

.TelegramInfo-module__paEPKXDXoSoqPpaDsWft {
  display: flex;
  justify-content: space-between;
}

.TelegramInfo-module__u9GyNs4XR4d7d8Jh8BWo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.TelegramInfo-module__qUR8L5J1cf1mlVeX2ahQ {
  width: 100%;
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/tabs/TelegramInfo/TelegramInfo.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB","sourcesContent":[".verification-code {\n  color: var(--primary-text-link);\n}\n\n.verification-code-text {\n  display: flex;\n  justify-content: space-between;\n}\n\n.automatic-connect-telegram-block {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 24px;\n}\n\n.field-command {\n  width: 100%;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verification-code": `TelegramInfo-module__xFKifzdlLVKOrnwF8YMF`,
	"verification-code-text": `TelegramInfo-module__paEPKXDXoSoqPpaDsWft`,
	"automatic-connect-telegram-block": `TelegramInfo-module__u9GyNs4XR4d7d8Jh8BWo`,
	"field-command": `TelegramInfo-module__qUR8L5J1cf1mlVeX2ahQ`
};
export default ___CSS_LOADER_EXPORT___;
