// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rotations-module__ywuZqmQlReA4PCB96bE3 {
  opacity: 0;
}

.Rotations-module__HLM4CNh3H5O1Jq4Snshw {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.Rotations-module__jKJbX6vpNLFzAjtDKLER {
  opacity: 1;
}

.Rotations-module__SgFApTREqqs1wRHloAZ1 {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
`, "",{"version":3,"sources":["webpack://./containers/Rotations/Rotations.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,iCAAiC;AACnC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,iCAAiC;AACnC","sourcesContent":[".enter {\n  opacity: 0;\n}\n\n.enterActive {\n  opacity: 1;\n  transition: opacity 500ms ease-in;\n}\n\n.exit {\n  opacity: 1;\n}\n\n.exitActive {\n  opacity: 0;\n  transition: opacity 500ms ease-in;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"enter": `Rotations-module__ywuZqmQlReA4PCB96bE3`,
	"enterActive": `Rotations-module__HLM4CNh3H5O1Jq4Snshw`,
	"exit": `Rotations-module__jKJbX6vpNLFzAjtDKLER`,
	"exitActive": `Rotations-module__SgFApTREqqs1wRHloAZ1`
};
export default ___CSS_LOADER_EXPORT___;
