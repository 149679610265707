// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IntegrationLabelsForm-module__LvJpYbq1FBYbCY_wBJv5 {
  margin: 0;
  list-style-type: none;

  > li {
    margin: 10px 0;
  }
}

.IntegrationLabelsForm-module__lAQHMfTFeeYMwDmS8r5g {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./containers/IntegrationLabelsForm/IntegrationLabelsForm.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,qBAAqB;;EAErB;IACE,cAAc;EAChB;AACF;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".labels-list {\n  margin: 0;\n  list-style-type: none;\n\n  > li {\n    margin: 10px 0;\n  }\n}\n\n.buttons {\n  width: 100%;\n  margin-top: 30px;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labels-list": `IntegrationLabelsForm-module__LvJpYbq1FBYbCY_wBJv5`,
	"buttons": `IntegrationLabelsForm-module__lAQHMfTFeeYMwDmS8r5g`
};
export default ___CSS_LOADER_EXPORT___;
