// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserSettingsParts-module__V3yC3g1n6ICVjS63ux5G {
  display: flex;
  flex-direction: column;
}

.UserSettingsParts-module__i9ShZ2i3lK6yIVlfmEkb {
  margin-top: 20px;
}

.UserSettingsParts-module__L6StM7ihlFuD5uRgFDlw {
  display: flex;
  flex-direction: row;
}

.UserSettingsParts-module__IzFXZK1xYXlk6qwktJPw {
  display: flex;
  flex-direction: column;
}

.UserSettingsParts-module__a7QZlBkfnaPJ5HHaPu7w {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.UserSettingsParts-module__wLLtUP0Py4E6VqRbI7ar {
  text-align: center;
  justify-content: center;
}

.UserSettingsParts-module__Q6EsWkeOC4fuuWhJ2oiK {
  display: flex;
  gap: 4px;
  width: 100%;
}

.UserSettingsParts-module__w4sMeKz_NDoc3NCJbMng {
  font-weight: 500;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
  width: 120px;
}

.UserSettingsParts-module__gZpczjA0PlwGQ26tGiWJ {
  flex-basis: 120px;
}

.UserSettingsParts-module__mxMI5d4XT63xD9nZk_sE {
  flex-basis: calc(100% - 120px);
}
`, "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/UserSettingsParts.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n}\n\n.content {\n  margin-top: 20px;\n}\n\n.columns {\n  display: flex;\n  flex-direction: row;\n}\n\n.col {\n  display: flex;\n  flex-direction: column;\n}\n\n.right {\n  margin-left: 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n.right_centered {\n  text-align: center;\n  justify-content: center;\n}\n\n.tag-container {\n  display: flex;\n  gap: 4px;\n  width: 100%;\n}\n\n.tag {\n  font-weight: 500;\n  font-size: 12px;\n  height: 32px;\n  line-height: 32px;\n  padding: 0 8px;\n  width: 120px;\n}\n\n.tag-left {\n  flex-basis: 120px;\n}\n\n.tag-right {\n  flex-basis: calc(100% - 120px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `UserSettingsParts-module__V3yC3g1n6ICVjS63ux5G`,
	"content": `UserSettingsParts-module__i9ShZ2i3lK6yIVlfmEkb`,
	"columns": `UserSettingsParts-module__L6StM7ihlFuD5uRgFDlw`,
	"col": `UserSettingsParts-module__IzFXZK1xYXlk6qwktJPw`,
	"right": `UserSettingsParts-module__a7QZlBkfnaPJ5HHaPu7w`,
	"right_centered": `UserSettingsParts-module__wLLtUP0Py4E6VqRbI7ar`,
	"tag-container": `UserSettingsParts-module__Q6EsWkeOC4fuuWhJ2oiK`,
	"tag": `UserSettingsParts-module__w4sMeKz_NDoc3NCJbMng`,
	"tag-left": `UserSettingsParts-module__gZpczjA0PlwGQ26tGiWJ`,
	"tag-right": `UserSettingsParts-module__mxMI5d4XT63xD9nZk_sE`
};
export default ___CSS_LOADER_EXPORT___;
