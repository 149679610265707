// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApiTokenSettings-module__M8UPdWWS8eS0JIyyhhIj {
  margin: 20px 0;
}

.ApiTokenSettings-module__sxHJRqcC3Bg0R69E3STw {
  margin: 20px 0;
}

.ApiTokenSettings-module__CMF7Y_1jYiiUotz_n9gI {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./containers/ApiTokenSettings/ApiTokenSettings.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":[".form {\n  margin: 20px 0;\n}\n\n.incident-matcher {\n  margin: 20px 0;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ApiTokenSettings-module__M8UPdWWS8eS0JIyyhhIj`,
	"incident-matcher": `ApiTokenSettings-module__sxHJRqcC3Bg0R69E3STw`,
	"header": `ApiTokenSettings-module__CMF7Y_1jYiiUotz_n9gI`
};
export default ___CSS_LOADER_EXPORT___;
