// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduleUserDetails-module__UzOMEOUGfvMCyPyn10eA {
  width: 260px;
  padding: 8px 4px;
}

.ScheduleUserDetails-module__dj5edqvzZAcLvsnUhImG {
  line-height: 16px;
  color: var(--primary-background);
  padding: 2px 7px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.ScheduleUserDetails-module__nQnZZ7roftiyZZNKyxHn {
  background: #6ccf8e;
}

.ScheduleUserDetails-module__udsj6ta7Tj2BV_bqRcJq {
  background: #ccccdc;
}

.ScheduleUserDetails-module__ZQUaR0DN1OllMtloCjFw {
  background: rgba(204, 204, 220, 0.4);
}

.ScheduleUserDetails-module__n53TetPDOLAoSG0jiAPt {
  width: 100vw;
  margin: 8px -14px 8px -14px;
}

.ScheduleUserDetails-module__C1ulCPGKz51koJBmqNVe {
  display: flex;
  flex-direction: column;
}

.ScheduleUserDetails-module__EqH3jDn4U7hIQXk1gdMH {
  color: #ccccdc;
}

.ScheduleUserDetails-module__F71HrNM6sH_KWQwjP1F2 {
  word-break: break-all;
}

.ScheduleUserDetails-module__p7_igM7SbZ4EopzKIXZr {
  display: flex;
  flex-grow: 1;
}

.ScheduleUserDetails-module__HYd6t2KaSnJMEQETTNGm {
  margin-right: 8px;
}

.ScheduleUserDetails-module__oMrT6g6NmThoq4C5OUmJ {
  margin-right: 8px;
}

.ScheduleUserDetails-module__t0_M7WNAw_G82UZFN59M {
  width: 50%;
  overflow-wrap: anywhere;
  margin-right: 8px;
}

.ScheduleUserDetails-module__gO8EQiDy05f3xSWA9dX5 {
  display: flex;
}

.ScheduleUserDetails-module__gO8EQiDy05f3xSWA9dX5 a {
  text-decoration-line: none;
  word-break: break-all;
}

.ScheduleUserDetails-module__LrgHHQvmRduWRkxrSo4c {
  margin-top: 4px;
  display: flex;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./containers/UsersTimezones/ScheduleUserDetails/ScheduleUserDetails.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gCAAgC;EAChC,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,0BAA0B;EAC1B,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,WAAW;AACb","sourcesContent":[".root {\n  width: 260px;\n  padding: 8px 4px;\n}\n\n.oncall-badge {\n  line-height: 16px;\n  color: var(--primary-background);\n  padding: 2px 7px;\n  border-radius: 4px;\n  margin-bottom: 10px;\n}\n\n.oncall-badge__type_now {\n  background: #6ccf8e;\n}\n\n.oncall-badge__type_inside {\n  background: #ccccdc;\n}\n\n.oncall-badge__type_outside {\n  background: rgba(204, 204, 220, 0.4);\n}\n\n.line-break {\n  width: 100vw;\n  margin: 8px -14px 8px -14px;\n}\n\n.times {\n  display: flex;\n  flex-direction: column;\n}\n\n.icon {\n  color: #ccccdc;\n}\n\n.username {\n  word-break: break-all;\n}\n\n.timezone-wrapper {\n  display: flex;\n  flex-grow: 1;\n}\n\n.timezone-icon {\n  margin-right: 8px;\n}\n\n.contact-icon {\n  margin-right: 8px;\n}\n\n.timezone-info {\n  width: 50%;\n  overflow-wrap: anywhere;\n  margin-right: 8px;\n}\n\n.contact-details {\n  display: flex;\n}\n\n.contact-details a {\n  text-decoration-line: none;\n  word-break: break-all;\n}\n\n.user-timezones {\n  margin-top: 4px;\n  display: flex;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ScheduleUserDetails-module__UzOMEOUGfvMCyPyn10eA`,
	"oncall-badge": `ScheduleUserDetails-module__dj5edqvzZAcLvsnUhImG`,
	"oncall-badge__type_now": `ScheduleUserDetails-module__nQnZZ7roftiyZZNKyxHn`,
	"oncall-badge__type_inside": `ScheduleUserDetails-module__udsj6ta7Tj2BV_bqRcJq`,
	"oncall-badge__type_outside": `ScheduleUserDetails-module__ZQUaR0DN1OllMtloCjFw`,
	"line-break": `ScheduleUserDetails-module__n53TetPDOLAoSG0jiAPt`,
	"times": `ScheduleUserDetails-module__C1ulCPGKz51koJBmqNVe`,
	"icon": `ScheduleUserDetails-module__EqH3jDn4U7hIQXk1gdMH`,
	"username": `ScheduleUserDetails-module__F71HrNM6sH_KWQwjP1F2`,
	"timezone-wrapper": `ScheduleUserDetails-module__p7_igM7SbZ4EopzKIXZr`,
	"timezone-icon": `ScheduleUserDetails-module__HYd6t2KaSnJMEQETTNGm`,
	"contact-icon": `ScheduleUserDetails-module__oMrT6g6NmThoq4C5OUmJ`,
	"timezone-info": `ScheduleUserDetails-module__t0_M7WNAw_G82UZFN59M`,
	"contact-details": `ScheduleUserDetails-module__gO8EQiDy05f3xSWA9dX5`,
	"user-timezones": `ScheduleUserDetails-module__LrgHHQvmRduWRkxrSo4c`
};
export default ___CSS_LOADER_EXPORT___;
